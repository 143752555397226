import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PageTitle from '../components/PageTitle'
import Container from '../components/Container'
import Layout from '../components/Layout'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const Text = styled.p`
  text-align: center;
  line-height: 1.6;
  a {
    color: ${props => props.theme.colors.text};
  }
`

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
  <Layout>
    <GatsbySeo
      title='404 | Sivua ei löydy | nettikasino2025.com'
      description='Valitettavasti sivu on poistunut tai osoite muuttunut.'
      
    />
    <Container>
      <PageTitle>Etsimääsi sivua ei lyödy</PageTitle>
      <Text>
        Valitettavasti emme löydä etsimääsi sivua. Se on joko poistettu tai siirretty. Palaa <Link to="/">etusivulle</Link> tai navigoi valikosta haluamallesi sivulle.
      </Text>
    </Container>
  </Layout>
  )
  );
};

export default NotFoundPage;
